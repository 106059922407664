<template>
    <div class="o-container || o-padding -medium-bottom -tiny-top">
        <h1 class="c-heading -h1">OpenSea Royalties Tracker - FVCK_AVATAR</h1>

        <div class="u-text-center || o-padding -tiny-bottom">
            <input @change="setDate" type="date" v-model="date">
            <button @click="clearDate" type="button">clear</button>
        </div>

        <div class="c-grid-assets" v-if="getAssetsEvents.length > 0">
            <div class="c-asset" v-for="(item, index) in getAssetsEvents" v-bind:key="index">
                <h2 class="c-heading -h3 || c-asset_title">
                    <a :href="item.asset.permalink" target="_blank" rel="noopener">
                        {{ item.asset.name }}
                    </a>

                    <span class="c-asset_title_icon">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 16.5 16.5" style="enable-background:new 0 0 16.5 16.5;" xml:space="preserve">
                        <g>
                            <polygon points="10,0 10,1.5 13.9,1.5 5.5,9.9 6.6,11 15,2.6 15,6.5 16.5,6.5 16.5,0  "/>
                            <path d="M12.5,14.1c0,0.5-0.4,0.9-0.9,0.9H2.4c-0.5,0-0.9-0.4-0.9-0.9V4.9C1.5,4.4,1.9,4,2.4,4h4.9V2.5H2.4C1.1,2.5,0,3.6,0,4.9
                                v9.2c0,1.3,1.1,2.4,2.4,2.4h9.2c1.3,0,2.4-1.1,2.4-2.4V9.2h-1.5V14.1z"/>
                        </g>
                        </svg>
                    </span>
                </h2>

                <img class="c-asset_image" :src="item.asset.image_preview_url" :alt="item.asset.name">

                <div class="c-asset_table">
                    <table class="c-table">
                        <thead>
                            <td></td>
                            <td>Sale Price</td>
                            <td>Royalties</td>
                        </thead>

                        <tr v-for="(event, index) in item.events" v-bind:key="index">
                            <td>
                                <a :href="'https://etherscan.io/tx/'+event.transaction.transaction_hash" target="_blank" rel="noopener">
                                    {{event.formated_date}}

                                    <svg class="c-asset_table_icon-external" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 16.5 16.5" style="enable-background:new 0 0 16.5 16.5;" xml:space="preserve">
                                    <g>
                                        <polygon points="10,0 10,1.5 13.9,1.5 5.5,9.9 6.6,11 15,2.6 15,6.5 16.5,6.5 16.5,0  "/>
                                        <path d="M12.5,14.1c0,0.5-0.4,0.9-0.9,0.9H2.4c-0.5,0-0.9-0.4-0.9-0.9V4.9C1.5,4.4,1.9,4,2.4,4h4.9V2.5H2.4C1.1,2.5,0,3.6,0,4.9
                                            v9.2c0,1.3,1.1,2.4,2.4,2.4h9.2c1.3,0,2.4-1.1,2.4-2.4V9.2h-1.5V14.1z"/>
                                    </g>
                                    </svg>
                                </a>
                            </td>
                            <td>
                                {{event.formated_total_price}}
                                <svg class="c-asset_table_icon-external" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.944 17.97L4.58 13.62 11.943 24l7.37-10.38-7.372 4.35h.003zM12.056 0L4.69 12.223l7.365 4.354 7.365-4.35L12.056 0z"/></svg>
                            </td>
                            <td>
                                {{event.royalties}}
                                <svg class="c-asset_table_icon-external" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.944 17.97L4.58 13.62 11.943 24l7.37-10.38-7.372 4.35h.003zM12.056 0L4.69 12.223l7.365 4.354 7.365-4.35L12.056 0z"/></svg>
                            </td>
                        </tr>

                        <tfoot>
                            <tr style="font-weight: 700;">
                                <td>Total</td>
                                <td>
                                    {{item.totalSale.price}}
                                    <svg class="c-asset_table_icon-external" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.944 17.97L4.58 13.62 11.943 24l7.37-10.38-7.372 4.35h.003zM12.056 0L4.69 12.223l7.365 4.354 7.365-4.35L12.056 0z"/></svg>
                                </td>
                                <td>
                                    {{item.totalSale.royalties}}
                                    <svg class="c-asset_table_icon-external" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11.944 17.97L4.58 13.62 11.943 24l7.37-10.38-7.372 4.35h.003zM12.056 0L4.69 12.223l7.365 4.354 7.365-4.35L12.056 0z"/></svg>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <div v-else>
            <p class="u-text-center">No sale events for this time period.</p>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: "Home",

    created() {
        this.setDataLoaded(true);
    },

    computed: {
        ...mapState('main', [
            'assetsEvents'
        ]),
        ...mapGetters('main', [
            'getAssetsEvents'
        ])
    },

    data() {
        return {
            date: ''
        }
    },

    watch: {
        getAssetsEvents: {
            handler(newValue) {
                console.log(newValue, 'watch');
            },
            immediate: true
        }
    },

    mounted() {
    },

    methods: {
        ...mapActions('main', ['setDataLoaded', 'clearAssetsEvents']),

        setDate(event) {
            const value = event.target.value;
            const date = new Date(value);
            const timestamp = date.getTime();

            this.clearAssetsEvents();

            this.$root.manageData(this.$root.idsChunks, timestamp);
        },

        clearDate() {
            this.date = '';

            this.clearAssetsEvents();
            this.$root.manageData(this.$root.idsChunks);
        }
    }
};
</script>
