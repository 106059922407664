const actions = {
    setFontsLoaded: (context, val) => {
        context.commit('SET_FONTS_LOADED', val)
    },
    setDataLoaded: (context, val) => {
        context.commit('SET_DATA_LOADED', val)
    },
    setAssetsEvents: (context, val) => {
        context.commit('SET_ASSETS_EVENTS', val)
    },
    clearAssetsEvents: (context) => {
        context.commit('CLEAR_ASSETS_EVENTS')
    }
}

export default actions;
