const mutations = {
    SET_FONTS_LOADED: (state, val) => {
        state.isFontsLoaded = val;
    },
    SET_DATA_LOADED: (state, val) => {
        state.isDataLoaded = val;
    },
    SET_ASSETS_EVENTS: (state, val) => {
        state.assetsEvents.push(...val);
    },
    CLEAR_ASSETS_EVENTS: (state) => {
        state.assetsEvents = [];
    }
}

export default mutations;
