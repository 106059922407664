function splitChunks(array, size) {
    const allChunks = [];

    for (let i = 0; i < array.length; i += size) {
        const chunk = array.slice(i, i + size);
        allChunks.push(chunk);
    }

    return allChunks;
}

export default splitChunks