import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    isFontsLoaded: false,
    isDataLoaded: false,
    assetsEvents: []
}

const store = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}

export default store;