import { createStore } from "vuex" 
import main from './main/index'
import metrics from './metrics/index'

const store = createStore({
    namespaced: true,
    modules: {
        main,
        metrics
    }
})

export default store;