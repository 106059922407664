
import { mapActions } from 'vuex';
import debounce from '../utils/debounce';
import { html } from "../utils/environment";

const initAppMixin = {
    created() {
        // Set fonts
        this.fonts = [
            { name: "NeueMontreal-Regular", style: 'normal', weight: 400 },
            { name: "PPSupplyMono-Regular", style: 'normal', weight: 400 }
        ]

        // Callbacks
        this.onFontsLoaded = () => {
            html.classList.add("is-fonts-loaded");

            requestAnimationFrame(() => {
                this.onResize()
            })
        }
    },

    mounted() {
        this.init()
    },

    methods: {
        ...mapActions('main', ['setFontsLoaded']),
        ...mapActions('metrics', ['setWidth', 'setHeight', 'setDevicePixelRatio']),

        ////////////////
        // Init
        ////////////////
        init() {

            // Load fonts
            this.fontsLoader(this.fonts,
                () => {
                    // Callback
                    this.onFontsLoaded?.()
                    // Update modules
                    this.$nextTick(() => {
                        this.setFontsLoaded(true)
                    })
                }
            );

            // Bind global events
            this.bindEvents();
        },

        ////////////////
        // Global events
        ////////////////
        bindEvents() {
            // Resize event
            window.addEventListener('resize', debounce(() => {
                this.onResize();
            }, 100, false))
        },

        onResize() {
            let vw = html.offsetWidth * 0.01;
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vw", `${vw}px`);
            document.documentElement.style.setProperty("--vh", `${vh}px`);

            this.setWidth(vw * 100)
            this.setHeight(vh * 100)
            this.setDevicePixelRatio(window.devicePixelRatio)
        },

        ////////////////
        // Fonts Loader
        ////////////////
        async fontsLoader(fonts, callback) {
            const fontFaceObservers = [];

            if (!fonts.length) return;

            fonts.forEach((font) => {
                const observer = this.loadFont(font.name, font.style, font.weight);
                fontFaceObservers.push(observer);
            });

            try {
                await Promise.all(fontFaceObservers);
                callback?.();
            } catch (err) {
                console.warn("Some critical font are not available:", err);
            }
        },

        loadFont(fontName, fontStyle, fontWeight) {
            return new Promise((resolve) => {

                let loop = null

                const clearLoop = () => {
                    if (loop) {
                        clearInterval(loop)
                        loop = null
                    }
                }

                const tryToLoadFont = () => {
                    let hasLoaded = false;

                    try {
                        hasLoaded = document.fonts.check(`${fontStyle} ${fontWeight} 16px ${fontName}`)
                    } catch (error) {
                        console.info(`CSS font loading API error with ${fontName} ${fontStyle} ${fontWeight}`, error);
                        clearLoop()
                        resolve();
                    }

                    if (hasLoaded) {
                        console.info(`${fontName} ${fontStyle} ${fontWeight} loaded`);
                        clearLoop()
                        resolve();
                    }
                }

                loop = setInterval(tryToLoadFont, 500);
            })
        }
    }
}

export default initAppMixin;
