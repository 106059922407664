import Web3 from "web3";

const web3Provider = new Web3(Web3.givenProvider || "ws://localhost:8545");

const getters = {
    isAllLoaded: state => {
        return state.isFontsLoaded && state.isDataLoaded
    },
    getAssetsEvents: state => {
        const results = [];

        //loop all asset
        for (let index = 0; index < state.assetsEvents.length; index++) {
            const totalSale = {
                price: 0,
                royalties: 0
            };

            const assetEvents = state.assetsEvents[index];

            //skip if no events
            if(!assetEvents.length > 0) {
                continue;
            }

            //loop all events
            for (let index = 0; index < assetEvents.length; index++) {
                const event = assetEvents[index];

                // Convert price from wei to ETH
                const price = event.total_price

                //update price
                event.formated_total_price = parseFloat(web3Provider.utils.fromWei(price, 'ether'));

                //add royalties
                event.royalties = ((event.formated_total_price * 0.069) * 0.875).toFixed(4);

                totalSale.royalties = parseFloat(totalSale.royalties + event.royalties);
                totalSale.price = parseFloat(totalSale.price + event.formated_total_price);

                event.formated_date = new Date(event.created_date).toISOString().split('T')[0];
            }

            //push results
            results.push({
                asset: assetEvents[0]?.asset || false,
                events: assetEvents,
                totalSale
            });
        }

        return results;
    },
};

export default getters;
