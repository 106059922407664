<template>
    <router-view :key="$route.fullPath" />

    <vue-loader />

</template>

<script>
import { mapActions } from 'vuex';
import "./assets/styles/main.scss";
import splitChunks from './utils/array.js';
import { seconds } from './utils/timer.js';

import LoaderVue from './components/Loader.vue';
import initAppMixin from "./mixins/initAppMixin";
import axios from "axios";

const TOKEN_IDS = [
    4,
    18,
    2,
    4497,
    2765,
    1618,
    3110,
    4361,
    8021,
    19,
    3877,
    3995,
    2949,
    3,
    5545,
    6,
    17,
    833,
    5,
    10
];

export default {
    name: "App",
    mixins: [initAppMixin],
    components: {
        "vue-loader": LoaderVue
    },

    async created() {
        this.idsChunks = splitChunks(TOKEN_IDS, 4);

        await seconds(1);
        this.manageData(this.idsChunks);
    },

    beforeUnmount() {

    },

    methods: {
        ...mapActions('main', [
            'setAssetsEvents'
        ]),

        fetchEvents(chunk, timestamp) {
            let promises = [];
            let events = [];

            const options = {
                method: 'GET',
                headers: { Accept: 'application/json', 'X-API-KEY': 'd521f7aa9f8c4ae7b85042e9e96bd18a' }
            };

            const params = {
                asset_contract_address: '0x10cdcb5a80e888ec9e9154439e86b911f684da7b',
                collection_slug: 'lvcidiaavatars',
                event_type: 'successful'
            };

            if(timestamp) {
                params.occurred_after = timestamp
            }

            for (let index = 0; index < chunk.length; index++) {
                params.token_id = chunk[index];

                let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

                promises.push(
                    axios.get(`https://api.opensea.io/api/v1/events?${queryString}`, options).then(response => {
                        events.push(response.data.asset_events);
                    })
                )
            }

            return Promise.all(promises).then(() => {
                this.setAssetsEvents(events);
            });
        },

        async manageData(chunks, timestamp = null) {
            for (let index = 0; index < chunks.length; index++) {
                const chunk = chunks[index];

                await this.fetchEvents(chunk, timestamp);
                await seconds(1);
            }
        }
    }
};
</script>
